import { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Td, Tr } from '@chakra-ui/react'

import { FieldColorScheme } from '~theme/foundations/colours'
import { createLikertValidationRules } from '~utils/fieldValidation'
import { LikertFieldRowRadioGroup } from '~templates/Field/Likert/LikertFieldRadioGroup'
import { LikertFieldInputs, LikertFieldSchema } from '~templates/Field/types'

import { usePublicLanguageStore } from '~features/public-form/usePublicLanguageStore'

interface LikertFieldRowProps {
  schema: LikertFieldSchema
  statement: string
  rowIndex: number
  colorScheme: FieldColorScheme
}

export const LikertFieldRow = ({
  schema,
  colorScheme,
  statement,
  rowIndex,
}: LikertFieldRowProps): JSX.Element => {
  const { publicI18n, isPublicView } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        publicI18n: state.publicI18n,
        isPublicView: state.isPublicView,
      }),
      [],
    ),
  )

  const { control } = useFormContext<LikertFieldInputs>()

  const inputName = useMemo(() => {
    return `${schema._id}.value.${rowIndex}` as const
  }, [schema, rowIndex])

  const validationRules = useMemo(
    () => createLikertValidationRules(schema, isPublicView, publicI18n),
    [schema, isPublicView, publicI18n],
  )

  return (
    <Tr key={statement}>
      <Td>{statement}</Td>
      <Controller
        control={control}
        name={inputName}
        rules={validationRules}
        render={({ field }) => (
          <LikertFieldRowRadioGroup
            schema={schema}
            colorScheme={colorScheme}
            {...field}
          />
        )}
      />
    </Tr>
  )
}
