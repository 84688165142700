import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { Box, useToken } from '@chakra-ui/react'
import type { ChartOptions } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

import { SummaryResponseBase } from '../../../../../../../../../shared/types/summary'

const STATEMENT_UNIT_HEIGHT = 125

export const LikertChart = ({ data }: { data: SummaryResponseBase }) => {
  const [green500, red500, blue500, cyan500, pink500] = useToken('colors', [
    'green.500',
    'red.500',
    'blue.500',
    'cyan.500',
    'pink.500',
    'black',
  ])

  const colorPallettes = useMemo(
    () => [blue500, red500, cyan500, green500, pink500],
    [green500, red500, blue500, cyan500, pink500],
  )

  const LIKERT_DATA = useMemo(() => {
    return data.summary.options
      ? data.summary.options?.map((option, idx) => ({
          label: option,
          data: data.summary.data[idx] ?? [],
          backgroundColor: colorPallettes[idx],
        }))
      : []
  }, [colorPallettes, data])

  const maxLikertDataValue = useMemo(
    () => Math.max(...LIKERT_DATA.flatMap((d) => d.data)),
    [LIKERT_DATA],
  )

  const OPTIONS: ChartOptions<'bar'> = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y' as const,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: data.question,
          align: 'start',
          font: {
            size: 14,
          },
          color: '#293044',
          padding: {
            top: 10,
            bottom: 40,
          },
        },
        datalabels: {
          display: (ctx: Context) => ctx.dataset.data[ctx.dataIndex] !== 0,
          color: 'black.900',
          anchor: 'end',
          align: 'end',
        },
      },
      scales: {
        x: {
          max: Math.ceil(maxLikertDataValue) + 1, // Add one unit to the max value
        },
      },
    }),
    [data.question, maxLikertDataValue],
  )

  return (
    <>
      <Box
        w={{ base: '270px', sm: '390px', md: '655px', lg: '800px' }}
        h={`${data.summary.options?.length * STATEMENT_UNIT_HEIGHT}px`}
        bg="white"
        px={{ base: '10px', sm: '20px', md: '30px', lg: '50px' }}
        py={{ base: '10px', sm: '20px', md: '30px', lg: '30px' }}
        boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
        borderRadius={'8px'}
      >
        <Bar
          data={{
            labels: data.summary.labels,
            datasets: LIKERT_DATA,
          }}
          options={OPTIONS}
        />
      </Box>
    </>
  )
}
