import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@chakra-ui/react'
import { extend, pick } from 'lodash'

import { LikertFieldBase } from '~shared/types'

import { createBaseValidationRules } from '~utils/fieldValidation'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'

import {
  SPLIT_TEXTAREA_TRANSFORM,
  SPLIT_TEXTAREA_VALIDATION,
  SPLIT_TEXTAREA_VALIDATION_WITH_LIMIT,
} from '~features/admin-form/create/builder-and-design/BuilderAndDesignDrawer/EditFieldDrawer/edit-fieldtype/common/constants'
import { FormFieldDrawerActions } from '~features/admin-form/create/builder-and-design/BuilderAndDesignDrawer/EditFieldDrawer/edit-fieldtype/common/FormFieldDrawerActions'
import { EditFieldProps } from '~features/admin-form/create/builder-and-design/BuilderAndDesignDrawer/EditFieldDrawer/edit-fieldtype/common/types'
import { useEditFieldForm } from '~features/admin-form/create/builder-and-design/BuilderAndDesignDrawer/EditFieldDrawer/edit-fieldtype/common/useEditFieldForm'
import { CreatePageDrawerContentContainer } from '~features/admin-form/create/common'
import { usePublicLanguageStore } from '~features/public-form/usePublicLanguageStore'

type EditLikertProps = EditFieldProps<LikertFieldBase>

const EDIT_LIKERT_FIELD_KEYS = [
  'title',
  'description',
  'required',
  'statements',
  'options',
] as const

type EditLikertKeys = typeof EDIT_LIKERT_FIELD_KEYS[number]

type EditLikertInputs = Pick<LikertFieldBase, EditLikertKeys> & {
  // Differs from statements and options in LikertFieldBase because input is a string. Will be converted to array using SPLIT_TEXTAREA_TRANSFORM
  // E.g., convert "string1\nstring2\nstring3" to ["string1", "string2", "string3"]
  fieldStatements: string
  fieldOptions: string
}

const transformLikertFieldToEditForm = (
  field: LikertFieldBase,
): EditLikertInputs => {
  return {
    ...pick(field, EDIT_LIKERT_FIELD_KEYS),
    fieldStatements: SPLIT_TEXTAREA_TRANSFORM.input(field.statements),
    fieldOptions: SPLIT_TEXTAREA_TRANSFORM.input(field.options),
  }
}

const transformLikertEditToField = (
  inputs: EditLikertInputs,
  originalField: LikertFieldBase,
): LikertFieldBase => {
  return extend({}, originalField, inputs, {
    statements: SPLIT_TEXTAREA_TRANSFORM.output(inputs.fieldStatements),
    options: SPLIT_TEXTAREA_TRANSFORM.output(inputs.fieldOptions),
  })
}

const MIN_NUMBER_OPTS = 3
const MAX_NUMBER_OPTS = 5

export const EditLikert = ({ field }: EditLikertProps): JSX.Element => {
  const { t } = useTranslation()

  const { publicI18n, isPublicView } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        publicI18n: state.publicI18n,
        isPublicView: state.isPublicView,
      }),
      [],
    ),
  )

  const {
    register,
    formState: { errors },
    buttonText,
    handleUpdateField,
    isLoading,
    handleCancel,
  } = useEditFieldForm<EditLikertInputs, LikertFieldBase>({
    field,
    transform: {
      input: transformLikertFieldToEditForm,
      output: transformLikertEditToField,
    },
    mode: 'onBlur',
  })

  const requiredValidationRule = useMemo(
    () =>
      createBaseValidationRules({ required: true }, isPublicView, publicI18n),
    [isPublicView, publicI18n],
  )

  return (
    <CreatePageDrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.question',
          )}
        </FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.description',
          )}
        </FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('required')}
          label={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.required',
          )}
        />
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.fieldStatements}
      >
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.statements',
          )}
        </FormLabel>
        <Textarea
          placeholder={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditCheckbox.enterOneOptionPerLine',
          )}
          {...register('fieldStatements', {
            validate: SPLIT_TEXTAREA_VALIDATION,
          })}
        />
        <FormErrorMessage>{errors?.fieldStatements?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.fieldOptions}
      >
        <FormLabel
          description={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditLikert.pleaseEnterBetweenLimit',
            {
              min: MIN_NUMBER_OPTS,
              max: MAX_NUMBER_OPTS,
            },
          )}
        >
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.options',
          )}
        </FormLabel>
        <Textarea
          placeholder={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditCheckbox.enterOneOptionPerLine',
          )}
          {...register('fieldOptions', {
            validate: SPLIT_TEXTAREA_VALIDATION_WITH_LIMIT(
              MIN_NUMBER_OPTS,
              MAX_NUMBER_OPTS,
            ),
          })}
        />
        <FormErrorMessage>{errors?.fieldOptions?.message}</FormErrorMessage>
      </FormControl>
      <FormFieldDrawerActions
        isLoading={isLoading}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </CreatePageDrawerContentContainer>
  )
}
