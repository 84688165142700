import { ControllerRenderProps } from 'react-hook-form'
import { Td, useRadioGroup } from '@chakra-ui/react'

import { FieldColorScheme } from '~theme/foundations/colours'
import Radio from '~components/Radio'
import { LikertFieldSchema } from '~templates/Field/types'

interface LikertFieldRowRadioGroupProps extends ControllerRenderProps {
  schema: LikertFieldSchema
  colorScheme: FieldColorScheme
}

export const LikertFieldRowRadioGroup = ({
  schema,
  colorScheme,
  ...props
}: LikertFieldRowRadioGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...props,
  })

  return (
    <>
      {schema.options.map((option) => {
        return (
          <Td
            {...getRootProps()}
            sx={{
              '.chakra-radio': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          >
            <Radio
              {...getRadioProps({ value: option })}
              colorScheme={colorScheme}
            />
          </Td>
        )
      })}
    </>
  )
}
