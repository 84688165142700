import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BiDownload } from 'react-icons/bi'
import {
  Box,
  Radio,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { BasicField } from '~shared/types'

import Button from '~components/Button'
import FormLabel from '~components/FormControl/FormLabel'
import Spinner from '~components/Spinner'

import { AugmentedDecryptedResponse } from '../ResponsesPage/storage/utils/augmentDecryptedResponses'

import { useMutateDownloadAttachments } from './mutations'

export interface DecryptedRowBaseProps {
  row: AugmentedDecryptedResponse
}
type DecryptedRowProps = DecryptedRowBaseProps & {
  secretKey: string
}

const DecryptedQuestionLabel = ({ row }: DecryptedRowBaseProps) => {
  return (
    <FormLabel questionNumber={`${row.questionNumber}.`} isRequired>
      {`${row.signature ? '[verified] ' : ''}${row.question}`}
    </FormLabel>
  )
}

const DecryptedHeaderRow = ({ row }: DecryptedRowBaseProps): JSX.Element => {
  return (
    <Text
      textStyle="h2"
      as="h2"
      color="primary.500"
      mb="0.5rem"
      _notFirst={{ mt: '2.5rem' }}
    >
      {row.question}
    </Text>
  )
}

const DecryptedTableRow = ({ row }: DecryptedRowBaseProps): JSX.Element => {
  return (
    <Stack>
      <DecryptedQuestionLabel row={row} />
      <Table variant="column-stripe" sx={{ tableLayout: 'fixed' }}>
        <Tbody>
          {row.answerArray?.map((row, idx) => (
            <Tr key={idx}>
              {Array.isArray(row) ? (
                row.map((col, cidx) => <Td key={cidx}>{col}</Td>)
              ) : (
                <Td>{row}</Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

const DecryptedAttachmentRow = ({ row, secretKey }: DecryptedRowProps) => {
  const { downloadAttachmentMutation } = useMutateDownloadAttachments()
  const i18n = useTranslation()
  const handleDownload = useCallback(() => {
    if (!row.downloadUrl || !row.answer) return
    return downloadAttachmentMutation.mutate({
      url: row.downloadUrl,
      secretKey,
      fileName: row.answer,
    })
  }, [downloadAttachmentMutation, row, secretKey])

  return (
    <Stack>
      <DecryptedQuestionLabel row={row} />
      <Text textStyle="body-1">
        {i18n.t<string>('responsePage.filename')}{' '}
        {row.answer && (
          <Button
            variant="link"
            aria-label="Download file"
            isDisabled={downloadAttachmentMutation.isLoading}
            onClick={handleDownload}
            rightIcon={
              downloadAttachmentMutation.isLoading ? (
                <Spinner fontSize="1.5rem" />
              ) : (
                <BiDownload fontSize="1.5rem" />
              )
            }
          >
            {row.answer}
          </Button>
        )}
      </Text>
    </Stack>
  )
}

const DecryptedLikertRowRadioGroup = ({
  statement,
  options,
  selected,
}: {
  statement: string
  options: string[]
  selected: string
}) => {
  return (
    <Tr>
      <Td>{statement}</Td>
      {options.map((option) => (
        <Td
          sx={{
            '.chakra-radio': {
              display: 'flex',
              justifyContent: 'center',
              cursor: 'auto',
            },
            '.chakra-radio:hover': {
              background: 'none',
            },
          }}
        >
          <Radio isChecked={option === selected} style={{ cursor: 'auto' }} />
        </Td>
      ))}
    </Tr>
  )
}

const DecryptedLikertRowLabel = ({
  row,
  question,
}: {
  row: AugmentedDecryptedResponse
  question: string
}) => {
  return (
    <FormLabel questionNumber={`${row.questionNumber}.`} isRequired>
      {`${row.signature ? '[verified] ' : ''}${question}`}
    </FormLabel>
  )
}

const DecryptedLikertRow = ({ row }: DecryptedRowBaseProps): JSX.Element => {
  const likertAnswerArray = row.answerArray as string[]
  const likertSeparationRegex = /^(.+?)\s+\((.*?)\)\s+\((.*?)\)$/
  const likertMatches = row.question.match(likertSeparationRegex)

  let question = ''
  let statements: string[] = []
  let options: string[] = []
  if (likertMatches) {
    question = likertMatches[1] // Extracts "Likert"
    statements = likertMatches[2].split(',').map((s) => s.trim()) // Extracts ["Statement 1", "Statement 2"]
    options = likertMatches[3].split(',').map((s) => s.trim()) // Extracts ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"]
  } else {
    console.error('Input string format is invalid.')
  }

  return (
    <Stack>
      <DecryptedLikertRowLabel row={row} question={question} />
      <Box
        d="block"
        w="100%"
        overflowX="auto"
        aria-label={`${row.questionNumber}. ${question}`}
        role="group"
      >
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: '200px' }} />
              {options.map((option) => (
                <Th
                  style={{
                    textAlign: 'center',
                    textTransform: 'none',
                    letterSpacing: '-0.006em',
                  }}
                  __css={{
                    '-webkit-padding-start': '1rem',
                    'padding-inline-start': '1rem',
                    '-webkit-padding-end': '1rem',
                    'padding-inline-end': '1rem',
                  }}
                >
                  {option}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {statements.map((statement, rowIndex) => (
              <DecryptedLikertRowRadioGroup
                statement={statement}
                options={options}
                selected={likertAnswerArray[rowIndex]}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  )
}

export const DecryptedRow = memo(
  ({ row, secretKey }: DecryptedRowProps): JSX.Element => {
    switch (row.fieldType) {
      case BasicField.Section:
        return <DecryptedHeaderRow row={row} />
      case BasicField.Attachment:
        return <DecryptedAttachmentRow row={row} secretKey={secretKey} />
      case BasicField.Table:
        return <DecryptedTableRow row={row} />
      case BasicField.Likert:
        return <DecryptedLikertRow row={row} />
      default:
        return (
          <Stack>
            <DecryptedQuestionLabel row={row} />
            {row.answer && <Text textStyle="body-1">{row.answer}</Text>}
            {row.answerArray && (
              <Text textStyle="body-1">{row.answerArray.join(', ')}</Text>
            )}
          </Stack>
        )
    }
  },
)
