import { useCallback, useMemo } from 'react'
import { FieldError, useFormContext, useFormState } from 'react-hook-form'
import { Box, Stack, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { get, head } from 'lodash'

import { FormColorTheme } from '~shared/types'

import { useIsMobile } from '~hooks/useIsMobile'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import { BaseFieldProps } from '~templates/Field/FieldContainer'
import { LikertFieldContainer } from '~templates/Field/Likert/LikertFieldContainer'
import { LikertFieldRow } from '~templates/Field/Likert/LikertFieldRow'
import { LikertFieldRowMobile } from '~templates/Field/Likert/mobile/LikertFieldRowMobile'
import { LikertFieldInputs, LikertFieldSchema } from '~templates/Field/types'

import {
  FieldBuilderState,
  stateDataSelector,
  useFieldBuilderStore,
} from '~features/admin-form/create/builder-and-design/useFieldBuilderStore'

export interface LikertFieldProps extends BaseFieldProps {
  schema: LikertFieldSchema
}

/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
export const LikertField = ({
  schema,
  colorTheme = FormColorTheme.Blue,
}: LikertFieldProps): JSX.Element => {
  const isMobile = useIsMobile()

  const fieldColorScheme = useMemo(
    () => `theme-${colorTheme}` as const,
    [colorTheme],
  )

  const formMethods = useFormContext<LikertFieldInputs>()
  const { errors } = useFormState({
    control: formMethods.control,
    name: schema._id,
  })

  const likertErrors = get(errors, schema._id)

  const uniqError = useMemo(() => {
    return get(
      head(likertErrors?.value as unknown as FieldError[] | undefined),
      'message',
    )
  }, [likertErrors])

  return (
    <LikertFieldContainer schema={schema}>
      <Box
        d="block"
        w="100%"
        overflowX="auto"
        aria-label={`${schema.questionNumber}. ${schema.title}`}
        role="group"
      >
        {!isMobile ? (
          <Table>
            <Thead>
              <Tr>
                <Th style={{ width: '40%' }}></Th>
                {schema.options.map((option) => (
                  <Th
                    style={{
                      textAlign: 'center',
                      textTransform: 'none',
                      letterSpacing: '-0.006em',
                    }}
                    __css={{
                      '-webkit-padding-start': '1rem',
                      'padding-inline-start': '1rem',
                      '-webkit-padding-end': '1rem',
                      'padding-inline-end': '1rem',
                    }}
                  >
                    {option}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {schema.statements.map((statement, rowIndex) => (
                <LikertFieldRow
                  schema={schema}
                  statement={statement}
                  rowIndex={rowIndex}
                  colorScheme={fieldColorScheme}
                />
              ))}
            </Tbody>
          </Table>
        ) : (
          <Stack>
            {schema.statements.map((statement, rowIndex) => (
              <LikertFieldRowMobile
                schema={schema}
                statement={statement}
                rowIndex={rowIndex}
                colorTheme={colorTheme}
              />
            ))}
          </Stack>
        )}
      </Box>
      <FormErrorMessage>{uniqError}</FormErrorMessage>
    </LikertFieldContainer>
  )
}
