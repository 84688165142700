import { Pie } from 'react-chartjs-2'
import { Box, useToken } from '@chakra-ui/react'
import type { ChartData, ChartOptions } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

import { SummaryResponseBase } from '~shared/types'

import { useIsMobile } from '~hooks/useIsMobile'

import { formatChartDataLabels } from '../../../utils'

interface PieChartProps {
  options: ChartOptions<'pie'>
  data: ChartData<'pie'>
}

export const PieChart = ({ data }: { data: SummaryResponseBase }) => {
  const isMobile = useIsMobile()
  const [
    blue500,
    green500,
    red500,
    orange500,
    yellow500,
    purple500,
    teal500,
    pink500,
    black,
  ] = useToken('colors', [
    'blue.500',
    'green.500',
    'red.500',
    'orange.500',
    'yellow.500',
    'purple.500',
    'teal.500',
    'pink.500',
    'black',
  ])

  const OPTIONS: PieChartProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return null
        },
        position: !isMobile ? ('right' as const) : ('bottom' as const),
        align: 'start',
        labels: {
          color: '#293044',
          font: {
            size: 12,
          },
        },
      },

      title: {
        display: true,
        text: data.question,
        align: 'start',
        font: {
          size: 14,
        },
        color: '#293044',
        padding: {
          top: 10,
          bottom: 40,
        },
      },
      datalabels: {
        display: (ctx: Context) => ctx.dataset.data[ctx.dataIndex] !== 0,
        formatter: (value: number, ctx: Context) =>
          formatChartDataLabels(value, ctx),
        color: black,
      },
    },
  }

  const PIE_DATA: PieChartProps['data'] = {
    labels: data.summary.labels,

    datasets: [
      {
        label: 'Count',
        data: data.summary.data as unknown as number[],
        backgroundColor: [
          blue500,
          green500,
          red500,
          orange500,
          yellow500,
          purple500,
          teal500,
          pink500,
        ],
      },
    ],
  }

  return (
    <Box
      bg={'white'}
      w={{ base: '270px', sm: '390px', md: '655px', lg: '800px' }}
      h={{ base: '380px', sm: '500px', md: '420px', lg: '400px' }}
      px={{ base: '10px', sm: '20px', md: '30px', lg: '50px' }}
      py={{ base: '10px', sm: '20px', md: '30px', lg: '30px' }}
      boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
      borderRadius={'8px'}
    >
      <Pie options={OPTIONS} data={PIE_DATA} width={550} height={400} />
    </Box>
  )
}
