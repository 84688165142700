import i18n from '~/i18n/i18n'

/**
 * A utility object for transforming data between an array of strings
 * and a multi-line string, typically for handling textarea input/output.
 *
 * @type {Object}
 * @property {Function} input - Converts an array of strings into a single multi-line string.
 * @property {Function} output - Converts a multi-line string into an array of trimmed, non-empty strings.
 */
export const SPLIT_TEXTAREA_TRANSFORM = {
  /**
   * Transforms an array of strings into a single string with each element on a new line.
   *
   * @param {string[]} optsArr - An array of strings to be transformed.
   * @returns {string} A single string with each array element on a new line.
   *
   * @example
   * const inputArray = ['Option 1', '', 'Option 2', '  Option 3  '];
   * const result = SPLIT_TEXTAREA_TRANSFORM.input(inputArray);
   * console.log(result);
   * // Output:
   * //"Option 1
   * // Option 2
   * // Option 3"
   */
  input: (optsArr: string[]) => optsArr.filter(Boolean).join('\n'),
  /**
   * Transforms a multi-line string into an array of trimmed, non-empty strings.
   *
   * @param {string} optsString - A string containing multiple lines to be split and cleaned.
   * @returns {string[]} An array of strings, with each line trimmed and empty lines removed.
   *
   * @example
   * const inputString = "Option 1\n\nOption 2\n  Option 3  ";
   * const result = SPLIT_TEXTAREA_TRANSFORM.output(inputString);
   * console.log(result);
   * // Output: ['Option 1', 'Option 2', 'Option 3']
   */
  output: (optsString: string) =>
    optsString
      .split('\n')
      .map((opt) => opt.trim())
      .filter(Boolean),
}

export const SPLIT_TEXTAREA_VALIDATION = {
  validate: (opts: string) => {
    const optsArr = SPLIT_TEXTAREA_TRANSFORM.output(opts)
    if (!optsArr.length)
      return i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseProvideAtLeast1Option',
      )
    return (
      new Set(optsArr).size === optsArr.length ||
      i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseRemoveDuplicateOptions',
      )
    )
  },
}

export const SPLIT_TEXTAREA_VALIDATION_WITH_LIMIT = (
  min: number,
  max: number,
) => ({
  validate: (opts: string) => {
    const optsArr = SPLIT_TEXTAREA_TRANSFORM.output(opts)
    if (!optsArr.length)
      return i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseProvideAtLeast1Option',
      )

    if (optsArr.length < min || max < optsArr.length)
      return i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseProvideOptionsBetweenTheLimit',
      )

    return (
      new Set(optsArr).size === optsArr.length ||
      i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseRemoveDuplicateOptions',
      )
    )
  },
})

export const DUPLICATE_OTHERS_VALIDATION = (hasOthers: boolean) => ({
  validate: (opts: string) => {
    if (!hasOthers) return true
    const optsArr = SPLIT_TEXTAREA_TRANSFORM.output(opts)
    return (
      !optsArr.includes('Others') ||
      i18n.t<string>(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.common.pleaseRemoveDuplicateOthersOptions',
      )
    )
  },
})
