import { Bar } from 'react-chartjs-2'
import { Box, useToken } from '@chakra-ui/react'
import type { ChartData, ChartOptions } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

import { SummaryResponseBase } from '~shared/types'

import { formatChartDataLabels } from '../../../utils'

interface BarChartProps {
  options: ChartOptions<'bar'>
  data: ChartData<'bar'>
}

export const VerticalBarChart = ({ data }: { data: SummaryResponseBase }) => {
  const [blue700] = useToken('colors', ['blue.700'])

  const OPTIONS: BarChartProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: data.question,
        align: 'start',
        font: {
          size: 14,
        },
        color: '#293044',
        padding: {
          top: 10,
          bottom: 40,
        },
      },
      datalabels: {
        formatter: (value: number, ctx: Context) =>
          formatChartDataLabels(value, ctx),

        anchor: 'end',
        align: 'end',
        offset: 0,
      },
    },
    scales: {
      y: {
        grace: '5%',
        ticks: {
          precision: 0,
        },
      },
    },
  }

  const VERTICAL_BAR_DATA: BarChartProps['data'] = {
    labels: data.summary.labels,
    datasets: [
      {
        label: '',
        data: data.summary.data,
        backgroundColor: [blue700],
        pointStyle: 'circle',
      },
    ],
  }

  return (
    <>
      <Box
        bg={'white'}
        w={{ base: '270px', sm: '390px', md: '655px', lg: '800px' }}
        h={{ base: '380px', sm: '500px', md: '420px', lg: '400px' }}
        px={{ base: '10px', sm: '20px', md: '30px', lg: '50px' }}
        py={{ base: '10px', sm: '20px', md: '30px', lg: '30px' }}
        boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
        borderRadius={'8px'}
      >
        <Bar
          options={OPTIONS}
          data={VERTICAL_BAR_DATA}
          width={550}
          height={400}
        />
      </Box>
    </>
  )
}
