import React, { useMemo } from 'react'
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form'
import {
  Box,
  SimpleGrid,
  Stack,
  Text,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react'

import { FormColorTheme } from '~shared/types'

import { LIKERT_THEME_KEY } from '~theme/components/Likert'
import { FieldColorScheme } from '~theme/foundations/colours'
import { LikertFieldInputs, LikertFieldSchema } from '~templates/Field/types'

interface LikertFieldRowMobileRowGroupProps extends ControllerRenderProps {
  colorTheme?: FormColorTheme
  schema: LikertFieldSchema
  colorScheme?: FieldColorScheme
}

const LikertFieldRowMobileRowGroupItem = ({
  colorTheme: c,
  children,
  radioProps,
}: {
  colorTheme: string
  children: React.ReactNode
  radioProps: UseRadioProps
}) => {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const styles = useMultiStyleConfig(LIKERT_THEME_KEY, { colorScheme: c })

  return (
    <Box as="label">
      <input {...input} />
      <Box {...checkbox} px={5} py={3} __css={styles.mobileItem}>
        {children}
      </Box>
    </Box>
  )
}

const LikertFieldRowMobileRowGroup = ({
  schema,
  colorTheme = FormColorTheme.Blue,
  ...props
}: LikertFieldRowMobileRowGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...props,
  })

  return (
    <SimpleGrid columns={{ sm: 2, md: 3 }} gap={3} {...getRootProps()}>
      {schema.options.map((option) => {
        const radio = getRadioProps({ value: option })
        return (
          <LikertFieldRowMobileRowGroupItem
            key={option}
            colorTheme={colorTheme}
            radioProps={radio}
          >
            {option}
          </LikertFieldRowMobileRowGroupItem>
        )
      })}
    </SimpleGrid>
  )
}

interface LikertFieldRowMobileProps {
  schema: LikertFieldSchema
  statement: string
  rowIndex: number
  colorTheme: FormColorTheme
}

export const LikertFieldRowMobile = ({
  schema,
  statement,
  rowIndex,
  colorTheme,
}: LikertFieldRowMobileProps) => {
  const { control } = useFormContext<LikertFieldInputs>()

  const inputName = useMemo(() => {
    return `${schema._id}.value.${rowIndex}` as const
  }, [schema, rowIndex])

  return (
    <Stack>
      <Box py={2}>
        <Text fontWeight={400}>{statement}</Text>
        <Controller
          control={control}
          name={inputName}
          render={({ field }) => (
            <LikertFieldRowMobileRowGroup
              schema={schema}
              colorTheme={colorTheme}
              {...field}
            />
          )}
        />
      </Box>
    </Stack>
  )
}
