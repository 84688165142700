import { Line } from 'react-chartjs-2'
import { Box, useToken } from '@chakra-ui/react'
import type { ChartData, ChartOptions, Color } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

import { SummaryResponseBase } from '~shared/types'

import { useIsMobile } from '~hooks/useIsMobile'

import { formatChartDataLabels } from '../../../utils'

interface AreaChartProps {
  options: ChartOptions<'line'>
  data: ChartData<'line'>
}

export const AreaChart = ({ data }: { data: SummaryResponseBase }) => {
  const isMobile = useIsMobile()
  const [blue700, orange600, white, black] = useToken('colors', [
    'blue.700',
    'orange.600',
    'black',
    'white',
  ])

  const PIE_CHART_OPTIONS: AreaChartProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return null
        },
        position: !isMobile ? ('bottom' as const) : ('bottom' as const),
        align: 'start',
        labels: {
          color: '#293044',
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: data.question,
        align: 'start',
        font: {
          size: 14,
        },
        color: '#293044',
        padding: {
          top: 10,
          bottom: 40,
        },
      },
      datalabels: {
        borderRadius: 5,
        padding: 6,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: (value: number, ctx: Context) =>
          formatChartDataLabels(value, ctx),

        backgroundColor: 'blue700',
      },
    },
  }

  const PIE_DATA: AreaChartProps['data'] = {
    labels: data.summary.labels,
    datasets: [
      {
        fill: true,
        tension: 0.5,
        label: 'Count',
        data: data.summary.data as unknown as number[],
        backgroundColor: [blue700],
      },
    ],
  }

  return (
    <Box
      bg={'white'}
      w={{ base: '270px', sm: '390px', md: '655px', lg: '800px' }}
      h={{ base: '380px', sm: '500px', md: '420px', lg: '400px' }}
      boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
      borderRadius={'8px'}
      px={{ base: '10px', sm: '20px', md: '30px', lg: '50px' }}
      py={{ base: '10px', sm: '20px', md: '30px', lg: '30px' }}
    >
      <Line
        options={PIE_CHART_OPTIONS}
        data={PIE_DATA}
        width={550}
        height={400}
      />
    </Box>
  )
}
