import { anatomy } from '@chakra-ui/theme-tools'

import { ComponentMultiStyleConfig } from '~theme/types'

export const LIKERT_THEME_KEY = 'Likert'

const parts = anatomy(LIKERT_THEME_KEY).parts('mobileItem')

export const Likert: ComponentMultiStyleConfig<typeof parts> = {
  parts: parts.keys,
  baseStyle: ({ colorScheme: c }) => ({
    mobileItem: {
      cursor: 'pointer',
      borderWidth: '1px',
      borderColor: `${c}.800`,
      _checked: {
        bg: `${c}.600`,
        color: 'white',
        borderColor: `${c}.600`,
      },
    },
  }),
  defaultProps: {
    colorScheme: 'primary',
  },
}
